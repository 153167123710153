import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.scss";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

const TemplateWrapper = ({ children, pageId, metadata }) => {
  const defaultMetadata = useSiteMetadata();
  const title =
    metadata && metadata.title ? `${metadata.title} | Impactual` : defaultMetadata.title;
  const description =
    metadata && metadata.description ? metadata.description : defaultMetadata.description;

  const [isScrolled, setIsScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isScrolled = currPos.y < -300;
      setIsScrolled(isScrolled);
    },
    [isScrolled]
  );

  return (
    <div className={`${pageId} ${isScrolled ? "is-scrolled" : ""}`}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicons/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicons/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://impactual.com" />
        <meta property="og:image" content="https://impactual.com/img/impactual.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@beImpactual" />
        <meta name="twitter:creator" content="@beImpactual" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://impactual.com/img/impactual.png" />
      </Helmet>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
