import React from "react";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="columns">

            <address className="column h4 is-6">

              <p>1200 18th Street NW Suite 700 Washington, DC 20036</p>

            </address>
            <div className="column is-2 follow" >
              Follow us
            </div>
            <div className="column is-4">
              <div className="social">

                <a title="Twitter" href="https://twitter.com/impactual" className="twitter" target="_blank" rel="noopener noreferrer">

                </a>


                <a title="Instagram" href="https://www.instagram.com/impactual/" className="instagram" target="_blank" rel="noopener noreferrer">

                </a>

                <a title="Facebook" href="https://www.facebook.com/beimpactual/" className="facebook" target="_blank" rel="noopener noreferrer">

                </a>

                <a title="Linkedin" href="https://www.linkedin.com/company/impactual/" className="linkedin" target="_blank" rel="noopener noreferrer">

                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
