import React, { useState } from "react";
import { Link } from "gatsby";

import Modal from "./Modal";
import logoWhite from "../img/logo-white.png";

const ExpandedMenuItems = ({closeModal}) => (
  <React.Fragment>
    <div className="columns">
      <div className="column is-3 ">
        <h3>WHAT WE DO</h3>
        <Link className="navbar-item" to="/work" onClick={closeModal}>
          Work
        </Link>
        <Link className="navbar-item" to="/about#services" onClick={closeModal}>
          Services
        </Link>
      </div>
      <div className="column is-3">
        <h3>WHO WE ARE</h3>
        <Link className="navbar-item" to="/about" onClick={closeModal}>
          About
        </Link>
        <Link className="navbar-item" to="/about#team" onClick={closeModal}>
          Team
        </Link>
      </div>
      <div className="column is-3">
        <h3>CONTENT + RESOURCES</h3>
        <Link className="navbar-item" to="/resources" onClick={closeModal}>
          Press
        </Link>
        <a className="navbar-item" href="https://www.impactual.shop" target="_blank" rel="noopener noreferrer">
          SHOP
        </a>
      </div>
      <div className="column is-3">
        <h3 className="no-header">CONTACT</h3>
        <Link className="navbar-item" to="/contact" onClick={closeModal}>
          Contact
        </Link>
        <Link className="navbar-item" to="/about#careers" onClick={closeModal}>
          Careers
        </Link>
      </div>
    </div>

  </React.Fragment>
);

const MenuItems = () => (
  <React.Fragment>
    <Link className="navbar-item" to="/about">
      About
    </Link>
    <Link className="navbar-item" to="/work">
      Work
    </Link>
    <Link className="navbar-item" to="/about#services">
      Services
    </Link>
    <Link className="navbar-item" to="/about#team">
      Team
    </Link>
  </React.Fragment>
);

const MobileNav = ({closeModal}) => (
  <div className="navbar-modal">
    <div className="container">
      <Link to="/" title="Logo">
        <img className="logo" src={logoWhite} alt="Impactual" width="150" />
      </Link>
      <div className="navbar-modal__menu">
        <ExpandedMenuItems closeModal={closeModal}/>
      </div>
    </div>
  </div>
);

const Navbar = () => {
  const [menuIsOpen, toggleMenu] = useState(false);
  const closeMenu = () => toggleMenu(false);
  const openMenu = () => toggleMenu(true);

  return (
    <nav className="navbar" role="navigation" aria-label="main-navigation">
      {menuIsOpen && <Modal content={<MobileNav closeModal={closeMenu}/>} closeModal={closeMenu} dark={true} />}
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logoWhite} alt="Impactual" width="150" />
          </Link>
          <button className="navbar-toggle" data-target="navMenu" onClick={openMenu}></button>
        </div>
        <div id="navMenu" className="navbar-menu">
          <div className="navbar-start">
            <MenuItems />
          </div>
          <button className="navbar-toggle desktop" data-target="navMenu" onClick={openMenu}></button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
